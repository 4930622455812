import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import Header from './components/Header';
import NewArrivals from './components/NewArrivals';
import FilterBar from './components/FilterBar';
import ProductList from './components/ProductList';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import MyLikes from './components/Mylikes';
import ProductUploadForm from './components/ProductUploadForm';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Men from './components/Mens';
import Women from './components/Women';
import UserProfile from './components/UserProfile';
import PriceFilter from './components/PriceFilter';
import BidsDashboard from './components/BidDashBoard';
import ChatPage from './components/ChatPage';
import SplashScreen from './components/SplashScreen';
import PasswordResetRequest from './components/PasswordResetRequest';
import PasswordResetForm from './components/PasswordReset';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import Account from './components/account';


axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;


const client = axios.create({
  baseURL: "https://www.refeiscollective.com"
});

function App() {
  const [products, setProducts] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); 
  const [sort, setSort] = useState('');
  const [priceRange, setPriceRange] = useState({ min: 0, max: 10000 });
  const [chestRange, setChestRange] = useState({ min: 0, max: 100 });
  const [fullLengthRange, setFullLengthRange] = useState({ min: 0, max: 100 });
  const [hipsRange, setHipsRange] = useState({ min: 0, max: 100 });
  const [shoulderRange, setShoulderRange] = useState({ min: 0, max: 100 });
  const [sleeveLengthRange, setSleeveLengthRange] = useState({ min: 0, max: 100 });
  const [waistRange, setWaistRange] = useState({ min: 0, max: 100 });
  const [letoutRange, setletoutRange] = useState({min:0, max:100})
  const [selectedDesigner, setSelectedDesigner] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  


  

  useEffect(() => {
    const initializeApp = async () => {
      try {
        // Fetch current user
        const userResponse = await client.get("/api/user");
        if (userResponse.status === 200 && userResponse.data.user) {  // Make sure to check userResponse.data.user
          setCurrentUser({
            user_id: userResponse.data.user.user_id,  
            username: userResponse.data.user.username,
            isAuthenticated: true
          });
        } else {
          setCurrentUser(null);  
        }
        // Fetch products
        const productsResponse = await client.get('/api/products/');
        setProducts(productsResponse.data);
      } catch (error) {
        console.error('Initialization error:', error);
        setCurrentUser({ isAuthenticated: false });
      }
      finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 1500); // Duration for the splash screen to be visible
      }
    };

    initializeApp();
  }, []);

  const handleProductUpload = (newProduct) => {
    setProducts(previousProducts => [newProduct, ...previousProducts]);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handlePriceChange = (min, max) => {
    setPriceRange({ min, max });
  };
  
  const handleChestChange = (min, max) => {
    setChestRange({ min, max });
  };

  const handleFullLengthChange = (min, max) => {
    setFullLengthRange({ min, max });
  };

  const handleHipsChange = (min, max) => {
    setHipsRange({ min, max });
  };

  const handleShoulderChange = (min, max) => {
    setShoulderRange({ min, max });
  };

  const handleSleeveLengthChange = (min, max) => {
    setSleeveLengthRange({ min, max });
  };

  const handleWaistChange = (min, max) => {
    setWaistRange({ min, max });
  };

  const handleletoutChange = (min, max) => {
    setletoutRange({ min, max });
  };


  const handleDesignerChange = (designer) => {
    setSelectedDesigner(designer);
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };


  const handleLogin = (user) => {
    setCurrentUser({
      user_id: user.user_id,
      username: user.username,
      isAuthenticated: true
    });
  };

  const handleLogout = () => {
    setCurrentUser(null);
  };

    useEffect(() => {
    const container = document.querySelector('.nikhil-container');
    if (container) {
      container.classList.add('fade-in');
    }
  }, [isLoading]);

  return (
    <Router>
      <div className="App">
        {isLoading ? (
          <SplashScreen />
        ) : (
          <>

            <div className="nikhil-container">
              <Header isAuthenticated={currentUser?.isAuthenticated} username={currentUser?.username} onLogout={handleLogout} />

              <div className="nikhil">
                <Routes>
                  <Route path="/" element={<><NewArrivals /><FilterBar searchTerm={searchTerm} handleSearchChange={handleSearchChange} sort={sort}
                    handleSortChange={handleSortChange}
                    /><hr /><div id="product-list" className="model-content">
                    <div className="filter-section">
                      <PriceFilter 
                        setPriceRange={setPriceRange} 
                        setChestRange={setChestRange} 
                        setFullLengthRange={setFullLengthRange} 
                        setHipsRange={setHipsRange} 
                        setShoulderRange={setShoulderRange} 
                        setSleeveLengthRange={setSleeveLengthRange} 
                        setWaistRange={setWaistRange} 
                        setletoutRange={setletoutRange} 
                        selectedDesigner={selectedDesigner} 
                        selectedYear={selectedYear}
                        onPriceChange={handlePriceChange}  
                        onPriceChange_1={handleChestChange} 
                        onPriceChange_2={handleFullLengthChange}
                        onPriceChange_3={handleHipsChange} 
                        onPriceChange_4={handleShoulderChange} 
                        onPriceChange_5={handleSleeveLengthChange}
                        onPriceChange_6={handleWaistChange} 
                        onPriceChange_7={handleletoutChange} 
                        onDesignerChange={handleDesignerChange} 
                        onYearChange={handleYearChange}
                      />
                    </div>
                    <div className="products-section">
                      <ProductList 
                        priceRange={priceRange} 
                        chestRange={chestRange} 
                        fullLengthRange={fullLengthRange} 
                        hipsRange={hipsRange} 
                        letoutRange={letoutRange} 
                        shoulderRange={shoulderRange}  
                        sleeveLengthRange={sleeveLengthRange} 
                        waistRange={waistRange} 
                        selectedDesigner={selectedDesigner} 
                        selectedYear={selectedYear}
                        sort={sort} 
                        searchTerm={searchTerm} 
                        products={products} 
                        currentUser={currentUser} 
                        isAuthenticated={currentUser?.isAuthenticated} 
                      />
                    </div>
                  </div>
                  </>} />

                  <Route path="/men" element={<><Men/><FilterBar searchTerm={searchTerm} handleSearchChange={handleSearchChange}
                    sort={sort} handleSortChange={handleSortChange} />
                    <hr /><div id="product-list" className="model-content_1"><div className="filter-section"><PriceFilter setPriceRange={setPriceRange} setChestRange={setChestRange} 
                    setFullLengthRange={setFullLengthRange} 
                    setHipsRange  = {setHipsRange} setShoulderRange = {setShoulderRange} setSleeveLengthRange = {setSleeveLengthRange}
                    setWaistRange = {setWaistRange} setletoutRange={setletoutRange} selectedDesigner = {selectedDesigner} selectedYear = {selectedYear}
                    onPriceChange={handlePriceChange}  onPriceChange_1={handleChestChange} onPriceChange_2={handleFullLengthChange}
                    onPriceChange_3={handleHipsChange} onPriceChange_4={handleShoulderChange} onPriceChange_5={handleSleeveLengthChange}
                    onPriceChange_6={handleWaistChange} onPriceChange_7={handleletoutChange} onDesignerChange={handleDesignerChange}
                     onYearChange={handleYearChange} /></div>
                    <div className="products-section"><ProductList priceRange={priceRange} chestRange={chestRange} fullLengthRange={fullLengthRange} hipsRange={hipsRange} 
                    shoulderRange={shoulderRange}  sleeveLengthRange={sleeveLengthRange} waistRange={waistRange} letoutRange={letoutRange} 
                    selectedDesigner={selectedDesigner} selectedYear={selectedYear}
                    sort={sort} searchTerm={searchTerm} category="men" isAuthenticated={currentUser?.isAuthenticated} /></div></div></>} />

<Route path="/women" element={
  <>
    <Women />
    <FilterBar 
      searchTerm={searchTerm} 
      handleSearchChange={handleSearchChange}
      sort={sort} 
      handleSortChange={handleSortChange} 
    /><hr/>
    <div id="product-list" className="model-content_1">
      <div className="filter-section">
        <PriceFilter 
          setPriceRange={setPriceRange} 
          setChestRange={setChestRange} 
          setFullLengthRange={setFullLengthRange} 
          setHipsRange={setHipsRange} 
          setShoulderRange={setShoulderRange} 
          setSleeveLengthRange={setSleeveLengthRange} 
          setWaistRange={setWaistRange} 
          setletoutRange={setletoutRange} 
          selectedDesigner={selectedDesigner} 
          selectedYear={selectedYear}
          onPriceChange={handlePriceChange}  
          onPriceChange_1={handleChestChange} 
          onPriceChange_2={handleFullLengthChange}
          onPriceChange_3={handleHipsChange} 
          onPriceChange_4={handleShoulderChange} 
          onPriceChange_5={handleSleeveLengthChange}
          onPriceChange_6={handleWaistChange} 
          onPriceChange_7={handleletoutChange} 
          onDesignerChange={handleDesignerChange}
          onYearChange={handleYearChange} 
        />
      </div>
      <div className="products-section">
        <ProductList 
          priceRange={priceRange} 
          chestRange={chestRange} 
          fullLengthRange={fullLengthRange} 
          hipsRange={hipsRange} 
          shoulderRange={shoulderRange}  
          sleeveLengthRange={sleeveLengthRange} 
          waistRange={waistRange} 
          letoutRange={letoutRange}
          selectedDesigner={selectedDesigner} 
          selectedYear={selectedYear}
          sort={sort} 
          searchTerm={searchTerm} 
          category="women" 
          products={products} 
          currentUser={currentUser} 
          isAuthenticated={currentUser?.isAuthenticated} 
        />
      </div>
    </div>
  </>
} />

                  
                  <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
                  <Route path="/register" element={<RegisterPage onRegister={handleLogin} />} />
                  <Route path="/my-likes" element={<MyLikes />} />
                  <Route path="/upload-product" element={<ProductUploadForm onProductUpload={handleProductUpload} isAuthenticated={currentUser?.isAuthenticated} />} />
                  <Route path="/profile" element={<UserProfile  currentUser={currentUser} /> } />
                  <Route path="/myprofile" element={<Account currentUser={currentUser} />}/>
                  <Route path="/my-bids" element={<BidsDashboard  />} />
                  <Route path="/chat/:chatRoomId" element={<ChatPage  isAuthenticated={currentUser?.isAuthenticated}/>} />
                  <Route path="/forgot-password" element={<PasswordResetRequest />} />
                  <Route path="/reset/:uidb64/:token/" element={<PasswordResetForm />} />
                </Routes>
              </div>
              <footer className="footer">
                <div className="container">
                  <p className="disclaimer">
                    Refeis Collective is not liable for any loss during use of this website. All transactions are at your own risk. Users are responsible for their own precautions. Continued use implies acceptance of these terms.
                  </p>
                  <div className="wrapper1000">
                    <a href="https://www.instagram.com/Refeiscollective" target="_blank" rel="noopener noreferrer" className="icon">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href="mailto:refeiscollective@outlook.com" className="icon">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </a>
                  </div>
                </div>
              </footer>
            </div>
          </>
        )}
      </div>
    </Router>
  );
}

export default App;



















