import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './PasswordReset.module.css'; 

const client = axios.create({
  baseURL: "https://www.refeiscollective.com",
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
});

client.interceptors.request.use(function (config) {
  const token = document.cookie.split('; ').find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
  config.headers['X-CSRFToken'] = token;
  return config;
});

const PasswordResetForm = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { uidb64, token } = useParams(); // Params from URL

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    try {
      const response = await client.post(`/api/reset/${uidb64}/${token}/`, { password });
      // Use the response object here
      if (response.status === 200) {
        setMessage("Your password has been reset successfully.");
        setTimeout(() => navigate('/login'), 3000);  // Redirect to login after 3 seconds
      } else {
        setMessage("Failed to reset password. Please try again.");
      }
    } catch (error) {
      console.error("Failed to reset password:", error);
      setMessage("Failed to reset password. Please try again.");
    }
  };

  return (
    <div className={styles.container12}>
        <h2>Reset Your Password</h2>
        <form onSubmit={handleSubmit} className={styles.form12}>
            <div className={styles['form-group']}>
                <label>
                    New Password:
                    <input
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                    />
                </label>
            </div>
            <div className={styles['form-group']}>
                <label>
                    Confirm New Password:
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                        required
                    />
                </label>
            </div>
            <button type="submit" className={styles['submit-btn']}>Reset Password</button>
        </form>
        {message && <p className={styles.alert}>{message}</p>}
    </div>
  );
};

export default PasswordResetForm;
