// import React from 'react';
// import './BidCard1.css';

// const BidCard1 = ({ bid, onUpdate, onDelete, onOpenChat, isAccepted, onEndChat }) => {
//     if (!bid) {
//         return <p>Loading bid information...</p>;
//     }

//     const handleUpdate = () => {
//         onUpdate(bid);
//     };

//     const handleDelete = () => {
//         onDelete(bid.id);
//     };

//     const handleChat = () => {
//         if (bid.chatRoomId) {  // Ensure chatRoomId is available
//             onOpenChat(bid.chatRoomId);  // Use chatRoomId instead of bid.id
//         } else {
//             alert("Chat room not available.");  // Error handling if chatRoomId is missing
//         }
//     };

//     const handleEndChat = () => {
//         if (bid.chatRoomId) {
//             onEndChat(bid.chatRoomId);
//         } else {
//             alert("Chat room not available.");
//         }
//     };
    
//     return (
//         <div className="bid-card">
//             <div className="bid-header">
//                 <h3>Bid to: {bid.recipientName}</h3>
//             </div>
//             <div className="bid-info">
//                 <p><strong>Item:</strong> {bid.product.name}</p>
//                 <p><strong>Amount:</strong> ${bid.amount}</p>
//                 <p><strong>Description:</strong> {bid.description}</p>
//             </div>
//             <div className="bid-actions_1">
//                 {isAccepted ? (
//                     <>
//                         <button1000 onClick={handleChat} className="chat-btn">Chat</button1000>
//                         <button1000 onClick={handleEndChat} className="end-chat-btn">End Chat</button1000>
//                     </>
//                 ) : (
//                     <>
//                         <button onClick={handleUpdate}>Update Bid</button>
//                         <button onClick={handleDelete}>Delete Bid</button>
//                     </>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default BidCard1;















// import React from 'react';
// import './BidCard1.css';

// const BidCard1 = ({ bid, onUpdate, onDelete, onOpenChat, isAccepted, onEndChat, onRemoveEndedChat }) => {
//     if (!bid) {
//         return <p>Loading bid information...</p>;
//     }

//     const handleUpdate = () => {
//         onUpdate(bid);
//     };

//     const handleDelete = () => {
//         onDelete(bid.id);
//     };

//     const handleChat = () => {
//         if (bid.chatRoomId) {
//             onOpenChat(bid.chatRoomId);
//         } else {
//             alert("Chat room not available.");
//         }
//     };

//     const handleEndChat = () => {
//         if (bid.chatRoomId) {
//             onEndChat(bid.chatRoomId);
//         } else {
//             alert("Chat room not available.");
//         }
//     };

//     const handleRemoveEndedChat = () => {
//         onRemoveEndedChat(bid.id);
//     };

//     return (
//         <div className="bid-card">
//             <div className="bid-header">
//                 <h3>Bid to: {bid.recipientName}</h3>
//             </div>
//             <div className="bid-info">
//                 <p><strong>Item:</strong> {bid.product.name}</p>
//                 <p><strong>Amount:</strong> ${bid.amount}</p>
//                 <p><strong>Description:</strong> {bid.description}</p>
//             </div>
//             <div className="bid-actions_1">
//                 {isAccepted ? (
//                     bid.is_chat_ended ? (
//                         <>
//                             <p>User has ended the chat</p>
//                             <button onClick={handleRemoveEndedChat} className="remove-chat-btn">Remove</button>
//                         </>
//                     ) : (
//                         <>
//                             <button1000 onClick={handleChat} className="chat-btn">Chat</button1000>
//                             <button1000 onClick={handleEndChat} className="end-chat-btn">End Chat</button1000>
//                         </>
//                     )
//                 ) : (
//                     <>
//                         <button onClick={handleUpdate}>Update Bid</button>
//                         <button onClick={handleDelete}>Delete Bid</button>
//                     </>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default BidCard1;








import React from 'react';
import './BidCard1.css';

const BidCard1 = ({ bid, onUpdate, onDelete, onOpenChat, isAccepted, onEndChat, onRemoveEndedChat, currentUser }) => {
    if (!bid) {
        return <p>Loading bid information...</p>;
    }

    const handleUpdate = () => {
        onUpdate(bid);
    };

    const handleDelete = () => {
        onDelete(bid.id);
    };

    const handleChat = () => {
        if (bid.chatRoomId) {
            onOpenChat(bid.chatRoomId);
        } else {
            alert("Chat room not available.");
        }
    };

    const handleEndChat = () => {
        if (bid.chatRoomId) {
            onEndChat(bid.chatRoomId);
        } else {
            alert("Chat room not available.");
        }
    };

    const handleRemoveEndedChat = () => {
        onRemoveEndedChat(bid.id);
    };

    return (
        <div className="bid-card">
            <div className="bid-header">
                <h3>Bid to: {bid.recipientName}</h3>
            </div>
            <div className="bid-info">
                <p><strong>Item:</strong> {bid.product.name}</p>
                <p><strong>Amount:</strong> ${bid.amount}</p>
                <p><strong>Description:</strong> {bid.description}</p>
            </div>
            <div className="bid-actions_1">
                                {bid.status === 'inactive' ? (
                        <>
                            {bid.ended_by === currentUser?.username ? (
                                <div className="action-buttons_100">
                                    <p>You have ended the chat. Waiting for removal...</p>
                                </div>
                            ) : (
                                <div className="action-buttons_100">
                                    <p>{bid.recipientName} has ended the chat</p>
                                    <button1000 onClick={handleRemoveEndedChat} className="remove1-chat-btn">Remove</button1000>
                                </div>
                            )}
                        </>
                    ) : (
                        isAccepted ? (
                            <>
                                <button onClick={handleChat} className="chat-btn">Chat</button>
                                <button onClick={handleEndChat} className="end-chat-btn">End Chat</button>
                            </>
                        ) : (
                            <>
                                <button onClick={handleUpdate}>Update Bid</button>
                                <button onClick={handleDelete}>Delete Bid</button>
                            </>
                        )
                    )}

            </div>
        </div>
    );
};

export default BidCard1;


