import React, { useState } from 'react';
import axios from 'axios';
import styles from './PasswordResetRequest.module.css'; // Import the CSS module
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const client = axios.create({
  baseURL: "https://www.refeiscollective.com",
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
});

client.interceptors.request.use(function (config) {
  const token = document.cookie.split('; ').find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
  config.headers['X-CSRFToken'] = token;
  return config;
});

const PasswordResetRequest = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await client.post("/api/reset_password/", { email });
      setMessage(response.data.message);
    } catch (error) {
      console.error("Error sending password reset email:", error);
      if (error.response) {
        setMessage(`${error.response.data.message || JSON.stringify(error.response.data)}`);
      } else if (error.request) {
        setMessage("No response from server. Check your network connection.");
      } else {
        setMessage("Error setting up password reset request.");
      }
    }
  };

  
  

  return (
    <div className={styles.container11}>
      <form onSubmit={handleSubmit} className={styles.form11}>
        <div className={styles.iconContainer}>
          <FontAwesomeIcon icon={faLock} size="3x" color="#333" />
        </div>
        <h2 className={styles.title}>Trouble with logging in?</h2>
        <p className={styles.instructions}>
          Enter your email address and we'll send you a link to get back into your account.
        </p>
        <input
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          className={styles.inputField11}
          placeholder="Email address"
          required
        />
        <button type="submit" className={styles.button11}>Send Login Link</button>
        {message && (
          <p className={message.includes("No user Found") ? styles.errorMessage11 : styles.successMessage11}>
            {message}
          </p>
        )}
        <div className={styles.options}>
          <div className={styles.orContainer}>
            <span className={styles.line}></span>
            <span className={styles.orText}>OR</span>
            <span className={styles.line}></span>
          </div>
          <p className={styles.optionLink}><Link to="/register">Create New Account</Link></p>
        </div>
        <div className={styles.footer}>
          <span className={styles.line}></span>
          <p className={styles.optionLink}><Link to="/login">Back to Login</Link></p>
        </div>
      </form>
    </div>
  );
};

export default PasswordResetRequest;
