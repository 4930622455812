import React, { useState } from 'react';
import './ChatList.css'

const ChatList = ({ rooms, onRoomSelect, selectedRoom }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredRooms = rooms.filter(room =>
        room.other_participant_names.some(name =>
            name.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    return (
        <div className="chat-list">
            <div className="chat-list-search12">
                <input
                    type="text"
                    placeholder="Search chats..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
            </div>
            {filteredRooms.length > 0 ? (
                filteredRooms.map(room => (
                    <div 
                        key={room.id}
                        className={`chat-list-item ${selectedRoom === room.id ? 'selected' : ''}`}
                        onClick={() => onRoomSelect(room.id)}
                    >
                        <div className="chat-list-name">
                            {room.other_participant_names.join(', ')}
                        </div>
                        <div className="chat-list-last-message">
                            {room.last_message ? `${room.last_message.message}` : "No messages"}
                        </div>
                    </div>
                ))
            ) : (
                <div className="chat-list-no-results">No chat rooms found.</div>
            )}
        </div>
    );
};

export default ChatList;
