import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ProductCard from './ProductCard';
import './UserProfile.css';
import UpdateProductModal from './UpdateProductModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const client = axios.create({
    baseURL: "https://www.refeiscollective.com",
    withCredentials: true,
});

client.interceptors.request.use(function (config) {
    const token = document.cookie.split('; ').find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
    config.headers['X-CSRFToken'] = token;
    return config;
});



const UserProfile = ({ currentUser }) => {
    const [userProducts, setUserProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

    useEffect(() => {
        if (!currentUser) {
            setError("Please log in to view your products.");
            return;
        }

        const fetchUserProducts = async () => {
            setIsLoading(true);
            try {
                const response = await client.get('/api/user-products/');
                setUserProducts(response.data);
                setError(null); // Clear previous errors if any
            } catch (error) {
                console.error('Error fetching user products:', error);
                setError('Failed to fetch products.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserProducts();
    }, [currentUser]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!currentUser) {
        return <div>Please log in to view your profile.</div>;
    }

    const MySwal = withReactContent(Swal);

    const handleDeleteProduct = async (productId) => {
        MySwal.fire({
          title: 'Are you sure?',
          text: 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const response = await client.delete(`/api/products/${productId}/`);
              if (response.status === 204) {
                // Refresh the list after deleting
                setUserProducts(userProducts.filter(product => product.id !== productId));
                MySwal.fire(
                  'Deleted!',
                  'Your product has been deleted.',
                  'success'
                );
              } else {
                throw new Error('Failed to delete the product');
              }
            } catch (error) {
              console.error('Error deleting product:', error);
              MySwal.fire(
                'Error!',
                'Failed to delete the product.',
                'error'
              );
            }
          }
        });
      };
      
      const handleOpenUpdateModal = (product) => {
        setSelectedProduct(product);
        setIsUpdateModalOpen(true);
    };

    const handleCloseUpdateModal = () => {
        setIsUpdateModalOpen(false);
    };

    const handleUpdateProduct = async (productId, updatedData) => {
        try {
            const response = await client.put(`/api/products/${productId}/`, updatedData);
            console.log('Received response:', response);  // Log the full response
            if (response.status === 200) {
                setUserProducts(userProducts.map(product =>
                    product.id === productId ? { ...product, ...response.data } : product
                ));
                toast.success("Product updated successfully!");
                handleCloseUpdateModal();
            } else {
                // If the status is not 200, handle potential error messages
                const errorData = response.data; // Directly accessing the data attribute
                alert(`Failed to update product: ${errorData.detail || 'Unknown error'}`);
            }
        } catch (error) {
            console.error('Failed to update product:', error);
            if (error.response) {
                // Error details are found in `error.response.data`
                console.error('Error data:', error.response.data);
            } else {
            }
        }
    };


    return (
        <div className="fade-in">
            <h1 className='up'> Uploaded Products</h1>
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <div className="products-container_10">
                    {userProducts.length ? (
                        userProducts.map(product => (
                            <ProductCard
                                key={product.id}
                                product={product}
                                onDelete={() => handleDeleteProduct(product.id)}
                                onEdit={() => handleOpenUpdateModal(product)}
                            />
                        ))
                    ) : (
                        <div></div>
                    )}
                </div>
            )}
            {isUpdateModalOpen && (
                <UpdateProductModal
                    product={selectedProduct}
                    onClose={handleCloseUpdateModal}
                    onUpdate={handleUpdateProduct}
                />
            )}
            <ToastContainer position="top-center" autoClose={5000} />
        </div>
    );
};

export default UserProfile;





