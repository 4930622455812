import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './account.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const client = axios.create({
  baseURL: "https://www.refeiscollective.com",
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
});

client.interceptors.request.use(function (config) {
  const token = document.cookie.split('; ').find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
  config.headers['X-CSRFToken'] = token;
  return config;
});

const Account = () => {
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await client.get('/api/user');
        if (response.data && response.data.user && response.data.user.email) {
          setEmail(response.data.user.email);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
  }, [email]);

  const handleNewEmailChange = (e) => setNewEmail(e.target.value);
  const handleCurrentPasswordChange = (e) => setCurrentPassword(e.target.value);
  const handleNewPasswordChange = (e) => setNewPassword(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handleUpdateEmail = async () => {
    try {
      const response = await client.post('/api/update-email/', {
        current_password: currentPassword,
        new_email: newEmail
      });
      setMessage(response.data.message);
      setIsEditingEmail(false);
    } catch (error) {
      setMessage(error.response.data.error);
    }
  };

  const handleUpdatePassword = async () => {
    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match");
      return;
    }

    try {
      const response = await client.post('/api/update-password/', {
        current_password: currentPassword,
        new_password: newPassword
      });
      setMessage(response.data.message);
      setIsEditingPassword(false);
    } catch (error) {
      setMessage(error.response.data.error);
    }
  };

  const handleDeleteAccount = async () => {
    MySwal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to delete your account? All data associated with this account will be permanently deleted.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        client.post('/api/delete-account/', {
          current_password: currentPassword
        }).then(response => {
          MySwal.fire(
            'Deleted!',
            'Your account has been deleted.',
            'success'
          );
          navigate('/'); // Redirect to home page after account deletion
        }).catch(error => {
          setMessage(error.response.data.error);
        });
      }
    });
  };

  return (
    <div className="account-container">
      <div className="account">
        <h2>Account Settings</h2>
        {message && <p className="message">{message}</p>}
  
        <div className="account-section">
          <div className="email-row">
            <label>Email:</label>
            <span className="email-display">{email}</span>
          </div>
          {isEditingEmail ? (
            <div className={`edit-email-section ${isEditingEmail ? 'active' : ''}`}>
              <input type="email" placeholder="Enter new email" value={newEmail} onChange={handleNewEmailChange} />
              <label>Current Password:</label>
              <div className="password-input">
                <input type={showPassword ? "text" : "password"} placeholder="Enter current password" value={currentPassword} onChange={handleCurrentPasswordChange} />
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={toggleShowPassword} />
              </div>
              <button onClick={handleUpdateEmail}>Update Email</button>
              <button onClick={() => setIsEditingEmail(false)}>Cancel</button>
            </div>
          ) : (
            <button onClick={() => setIsEditingEmail(true)}>Edit Email</button>
          )}
        </div>
  
        <div className="account-section">
          <div className="password-row">
            <label>Password:</label>
          </div>
          {isEditingPassword ? (
            <div className={`edit-password-section ${isEditingPassword ? 'active' : ''}`}>
              <label>Current Password:</label>
              <div className="password-input">
                <input type={showPassword ? "text" : "password"} placeholder="Enter current password" value={currentPassword} onChange={handleCurrentPasswordChange} />
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={toggleShowPassword} />
              </div>
              <label>New Password:</label>
              <div className="password-input">
                <input type={showPassword ? "text" : "password"} placeholder="Enter new password" value={newPassword} onChange={handleNewPasswordChange} />
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={toggleShowPassword} />
              </div>
              <label>Confirm Password:</label>
              <div className="password-input">
                <input type={showPassword ? "text" : "password"} placeholder="Confirm new password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={toggleShowPassword} />
              </div>
              <button onClick={handleUpdatePassword}>Update Password</button>
              <button onClick={() => setIsEditingPassword(false)}>Cancel</button>
            </div>
          ) : (
            <button onClick={() => setIsEditingPassword(true)}>Edit Password</button>
          )}
        </div>
  
        <div className="account-section">
          <button onClick={handleDeleteAccount} className="delete-account">Delete Account</button>
        </div>
      </div>
    </div>
  );
  
  


};

export default Account;
