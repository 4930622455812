import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BidCard from './BidCard';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const client = axios.create({
    baseURL: "https://www.refeiscollective.com",
    withCredentials: true,
});

client.interceptors.request.use(function (config) {
    const token = document.cookie.split('; ').find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
    if (token) {
        config.headers['X-CSRFToken'] = token;
    }
    return config;
});

const ReceivedBids = () => {
    const [bids, setBids] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const initializeApp = async () => {
            try {
                const userResponse = await client.get("/api/user");
                if (userResponse.status === 200 && userResponse.data.user) {
                    setCurrentUser({
                        user_id: userResponse.data.user.user_id,
                        username: userResponse.data.user.username,
                        isAuthenticated: true
                    });
                } else {
                    setCurrentUser(null);
                }
            } catch (error) {
                console.error('Failed to fetch user:', error);
                setCurrentUser(null);
            }
        };

        initializeApp();
    }, []);

    useEffect(() => {
        const fetchBids = async () => {
            try {
                const response = await client.get('/api/bids/received/');
                const activeBids = response.data.filter(bid => bid.status !== 'inactive' || bid.ended_by !== currentUser?.username);
                setBids(activeBids);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch received bids:', error);
                setLoading(false);
            }
        };

        if (currentUser) {
            fetchBids();
        }
    }, [currentUser]);

    const handleAcceptBid = async (bidId) => {
        try {
            const response = await client.put(`/api/bids/accept/${bidId}/`);
            if (response.status === 200) {
                const chatRoomId = response.data.chatRoomId;
                setBids(bids.map(bid => bid.id === bidId ? { ...bid, status: 'accepted' } : bid));
                
                Swal.fire({
                    icon: 'success',
                    title: 'Bid accepted successfully!',
                    text: 'You can now chat with the bidder.',
                    confirmButtonText: 'Go to Chat'
                }).then(() => {
                    navigate(`/chat/${chatRoomId}`);
                });
            }
        } catch (error) {
            console.error('Failed to accept bid:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error accepting bid',
            });
        }
    };

    const handleDeclineBid = async (bidId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, decline it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await client.delete(`/api/bids/${bidId}/`);
                    if (response.status === 204) {
                        const updatedBids = bids.filter(bid => bid.id !== bidId);
                        setBids(updatedBids);
                        Swal.fire('Declined!', 'Bid has been declined.', 'success');
                    }
                } catch (error) {
                    console.error('Failed to decline bid:', error);
                    Swal.fire('Error', 'Error declining bid', 'error');
                }
            }
        });
    };

    const handleOpenChat = (chatRoomId) => {
        navigate(`/chat/${chatRoomId}`);
    };

    const handleEndChat = async (chatRoomId, bidId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, end it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await client.post(`/api/end-chat/${chatRoomId}/`, { ended_by: 'received' });
                    if (response.status === 200) {
                        const updatedBids = bids.filter(bid => bid.id !== bidId);
                        setBids(updatedBids);
                        Swal.fire('Ended!', 'Chat has been ended.', 'success');
                    }
                } catch (error) {
                    console.error('Failed to end chat:', error);
                    Swal.fire('Error', 'Error ending chat', 'error');
                }
            }
        });
    };

    const handleRemoveEndedChat = async (bidId) => {
        try {
            await client.delete(`/api/bids/${bidId}/`);
            const updatedBids = bids.filter(bid => bid.id !== bidId);
            setBids(updatedBids);  
        } catch (error) {
            console.error('Failed to remove ended chat:', error);
            alert('Error removing ended chat');
        }
    };

    if (loading) {
        return <p className="loading-text">Loading...</p>;
    }

    return (
        <div className="active-bids-container">
            {bids.length > 0 ? (
                bids.map(bid => (
                    <BidCard 
                        key={bid.id} 
                        bid={bid} 
                        onDecline={handleDeclineBid} 
                        onAccept={handleAcceptBid}
                        onOpenChat={handleOpenChat}
                        onEndChat={() => handleEndChat(bid.chatRoomId, bid.id)}
                        onRemoveEndedChat={handleRemoveEndedChat}
                        receiverUsername={currentUser?.username}
                    />
                ))
            ) : (
                <p>No received bids found</p>
            )}
        </div>
    );
};

export default ReceivedBids;
