import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ActiveBids.css';
import BidCard1 from './BidCard1';
import UpdateBidModal from './UpdateBidModal';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';


const client = axios.create({
    baseURL: "https://www.refeiscollective.com",
    withCredentials: true,
});

// Intercept every request to include the CSRF token
client.interceptors.request.use(function (config) {
    const token = document.cookie.split('; ').find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
    if (token) {
        config.headers['X-CSRFToken'] = token;
    }
    return config;
});

const ActiveBids = () => {
    const [bids, setBids] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [currentBid, setCurrentBid] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await client.get('/api/user');
                if (response.status === 200 && response.data.user) {
                    setCurrentUser(response.data.user);
                }
            } catch (error) {
                console.error('Failed to fetch user:', error);
            }
        };

        fetchUser();
    }, []);

    useEffect(() => {
        const fetchBids = async () => {
            try {
                const response = await client.get('/api/bids/active/');
                setBids(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch active bids:', error);
                setLoading(false);
            }
        };
        if (currentUser) {
            fetchBids();
        }
    }, [currentUser]);

    const handleUpdateBid = (bid) => {
        setCurrentBid(bid);
    };

    const handleUpdateSubmit = async (bidId, updatedData) => {
        try {
            const response = await client.put(`/api/bids/${bidId}/`, updatedData);
            if (response.status === 200) {
                setBids(bids.map(bid => bid.id === bidId ? { ...bid, ...updatedData } : bid));
                toast.success('Bid updated successfully!');
            }
        } catch (error) {
            console.error('Failed to update bid:', error);
            toast.error('Error updating bid');
        }
    };

    const handleDeleteBid = async (bidId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await client.delete(`/api/bids/${bidId}`);
                    if (response.status === 204) {
                        setBids(bids.filter(bid => bid.id !== bidId));
                        Swal.fire('Deleted!', 'Bid has been deleted.', 'success');
                    }
                } catch (error) {
                    console.error('Failed to delete bid:', error);
                    Swal.fire('Error', 'Error deleting bid', 'error');
                }
            }
        });
    };

    const handleOpenChat = (chatRoomId) => {
        navigate(`/chat/${chatRoomId}`);
    };

    const handleEndChat = async (chatRoomId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, end it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await client.post(`/api/end-chat/${chatRoomId}/`);
                    if (response.status === 200) {
                        // Update the bids array to reflect the chat has ended, marking it as inactive
                        const updatedBids = bids.map(bid => {
                            if (bid.chatRoomId === chatRoomId) {
                                return { ...bid, status: 'inactive', ended_by: currentUser?.username };
                            }
                            return bid;
                        });
                        setBids(updatedBids.filter(bid => bid.status !== 'inactive' || bid.ended_by !== currentUser?.username));
                        Swal.fire('Ended!', 'Chat has been ended.', 'success');
                    }
                } catch (error) {
                    console.error('Failed to end chat:', error);
                    Swal.fire('Error', 'Error ending chat', 'error');
                }
            }
        });
    };
    

    const handleRemoveEndedChat = async (bidId) => {
        try {
            await client.delete(`/api/bids/${bidId}/`);
            setBids(bids.filter(bid => bid.id !== bidId));
        } catch (error) {
            console.error('Failed to remove ended chat:', error);
            toast.error('Error removing ended chat');
        }
    };

    return (
        <div className="active-bids-container">
            {loading ? <p className="loading-text">Loading...</p> : 
                bids.length > 0 ? (
                    bids.map(bid => (
                        <BidCard1 
                            key={bid.id} 
                            bid={bid} 
                            onDelete={handleDeleteBid} 
                            onUpdate={() => handleUpdateBid(bid)} 
                            onOpenChat={handleOpenChat} 
                            isAccepted={bid.status === 'accepted'}
                            onEndChat={handleEndChat}
                            onRemoveEndedChat={handleRemoveEndedChat}
                            currentUser={currentUser}
                        />
                    ))
                ) : (
                    <p>No active bids found</p>
                )
            }
            {currentBid && <UpdateBidModal bid={currentBid} onClose={() => setCurrentBid(null)} onUpdate={handleUpdateSubmit} />}
            <ToastContainer 
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};

export default ActiveBids;
