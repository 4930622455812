import React, {  } from 'react';
import './SplashScreen.css';

const SplashScreen = () => {


    return (
        <div className="splash-screen">
            <div className="content567">
                <h1 className="site-name">Refeis Collective</h1>
                <p className="tagline">Discover Luxury, Embrace Elegance</p>
            </div>
        </div>
    );
}

export default SplashScreen;
