import React, { useState, useEffect } from 'react';
import { Range } from 'react-range';
import './PriceFilter.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH, faTimes } from '@fortawesome/free-solid-svg-icons';

const client = axios.create({
  baseURL: "https://www.refeiscollective.com",
  withCredentials: true,
});

client.interceptors.request.use(function (config) {
    const token = document.cookie.split('; ').find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
    config.headers['X-CSRFToken'] = token;
    return config;
});


const PriceFilter = ({
  onPriceChange,
  onPriceChange_1,
  onPriceChange_2,
  onPriceChange_3,
  onPriceChange_4,
  onPriceChange_5,
  onPriceChange_6,
  onPriceChange_7,
  onDesignerChange,
  onYearChange,
}) => {
  const [priceRange, setPriceRange] = useState([0, 10000]);
  const [chestRange, setChestRange] = useState([0, 100]);
  const [fullLengthRange, setFullLengthRange] = useState([0, 100]);
  const [hipsRange, setHipsRange] = useState([0, 100]);
  const [shoulderRange, setShoulderRange] = useState([0, 100]);
  const [sleeveLengthRange, setSleeveLengthRange] = useState([0, 100]);
  const [waistRange, setWaistRange] = useState([0, 100]);
  const [letoutRange, setletoutRange] = useState([0,100]);
  const [designers, setDesigners] = useState([]); 
  const [selectedDesigner, setSelectedDesigner] = useState('');
  const [years, setYear] = useState([]); 
  const [selectedYear, setSelectedYear] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);


  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);



  useEffect(() => {
    // Fetch available designers
    client.get('api/designers/')
      .then(response => {
        setDesigners(response.data.designers);
      })
      .catch(error => {
        console.error('Error fetching designers:', error);
      });

      client.get('api/years/')
      .then(response => {
        setYear(response.data.years);
      })
      .catch(error => {
        console.error('Error fetching years:', error);
      });

  }, []);

  const handleRangeChange = (values) => {
    setPriceRange(values);
    onPriceChange(values[0], values[1]);  
};


  const handleChestChange = (values) => {
    setChestRange(values);
    onPriceChange_1(values[0], values[1]);
  };

  const handleFullLengthChange = (values) => {
    setFullLengthRange(values);
    onPriceChange_2(values[0], values[1]);
  };

  const handleHipsChange = (values) => {
    setHipsRange(values);
    onPriceChange_3(values[0], values[1]);
  };

  const handleShoulderChange = (values) => {
    setShoulderRange(values);
    onPriceChange_4(values[0], values[1]);
  };

  const handleSleeveLengthChange = (values) => {
    setSleeveLengthRange(values);
    onPriceChange_5(values[0], values[1]);
  };

  const handleWaistChange = (values) => {
    setWaistRange(values);
    onPriceChange_6(values[0], values[1]);
  };

  const handleletoutChange = (values) => {
    setletoutRange(values);
    onPriceChange_7(values[0], values[1]);
  };

  const handleDesignerChange = (event) => {
    const designer = event.target.value;
    setSelectedDesigner(designer);
    onDesignerChange(designer);
  };

  const handleYearChange = (event) => {
    const year = event.target.value;
    setSelectedYear(year);
    onYearChange(year);
  };

  const toggleAdvancedFilters = () => {
    setShowAdvancedFilters(!showAdvancedFilters);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (window.innerWidth <= 1010) {
      document.body.classList.toggle('blur-background', !isMenuOpen);
    }
  };
  

  return (
    <div className="filter-container_1">
        <button className="filter-toggle" onClick={toggleMenu}>
          <FontAwesomeIcon icon={isMenuOpen ? faTimes : faSlidersH} />
        </button>
        <div className={isMenuOpen ? "filter-content open" : "filter-content"}>
            <div className="filter-header">
              <h2 className="filter-title">Filter</h2>
            </div>
            <div className="price-filter">
              <div className="circle-input">
                <span className="currency-symbol">$</span>
                <input
                  type="number"
                  value={priceRange[0]}
                  onChange={(e) => handleRangeChange([Number(e.target.value), priceRange[1]])}
                />
              </div>
              <div className="filter-title-center">
                Price
              </div>
              <div className="circle-input">
                <span className="currency-symbol">$</span>
                <input
                  type="number"
                  value={priceRange[1]}
                  onChange={(e) => handleRangeChange([priceRange[0], Number(e.target.value)])}
                />
              </div>
            </div>
            <div className="slider-container">
              <Range
                step={100}
                min={0}
                max={10000}
                values={priceRange}
                onChange={handleRangeChange}
                renderTrack={({ props, children }) => (
                  <div {...props} className="slider-track">
                    {children}
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div {...props} className="slider-thumb" />
                )}
              />
            </div>
          <button onClick={toggleAdvancedFilters} className="advanced-filters-btn">
            {showAdvancedFilters ? 'Hide Advanced Filters' : 'Show Advanced Filters +'}
          </button>
          {showAdvancedFilters && (
            <div className="advanced-filters" style={{ maxHeight: showAdvancedFilters ? '1500px' : '0' }}>
                <div className="chest-filter">
                <div className="circle-input">
                  <span className="currency-symbol">"</span>
                  <input
                    type="number"
                    value={chestRange[0]}
                    onChange={(e) => handleChestChange([Number(e.target.value), chestRange[1]])}
                  />
                </div>
                <div className="filter-title-center">
                  Chest
                </div>
                <div className="circle-input">
                  <span className="currency-symbol">"</span>
                  <input
                    type="number"
                    value={chestRange[1]}
                    onChange={(e) => handleChestChange([chestRange[0], Number(e.target.value)])}
                  />
                </div>
              </div>
              <div className="slider-container">
                <Range
                  step={1}
                  min={0}
                  max={100}
                  values={chestRange}
                  onChange={handleChestChange}
                  renderTrack={({ props, children }) => (
                    <div {...props} className="slider-track">
                      {children}
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div {...props} className="slider-thumb" />
                  )}
                />
              </div>
              <div className="full-length-filter">
                <div className="circle-input">
                  <span className="currency-symbol">"</span>
                  <input
                    type="number"
                    value={fullLengthRange[0]}
                    onChange={(e) => handleFullLengthChange([Number(e.target.value), fullLengthRange[1]])}
                  />
                </div>
                <div className="filter-title-center">
                  Full Length
                </div>
                <div className="circle-input">
                  <span className="currency-symbol">"</span>
                  <input
                    type="number"
                    value={fullLengthRange[1]}
                    onChange={(e) => handleFullLengthChange([fullLengthRange[0], Number(e.target.value)])}
                  />
                </div>
              </div>
              <div className="slider-container">
                <Range
                  step={1}
                  min={0}
                  max={100}
                  values={fullLengthRange}
                  onChange={handleFullLengthChange}
                  renderTrack={({ props, children }) => (
                    <div {...props} className="slider-track">
                      {children}
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div {...props} className="slider-thumb" />
                  )}
                />
              </div>
              <div className="hips-filter">
                <div className="circle-input">
                  <span className="currency-symbol">"</span>
                  <input
                    type="number"
                    value={hipsRange[0]}
                    onChange={(e) => handleHipsChange([Number(e.target.value), hipsRange[1]])}
                  />
                </div>
                <div className="filter-title-center">
                  Hips
                </div>
                <div className="circle-input">
                  <span className="currency-symbol">"</span>
                  <input
                    type="number"
                    value={hipsRange[1]}
                    onChange={(e) => handleHipsChange([hipsRange[0], Number(e.target.value)])}
                  />
                </div>
              </div>
              <div className="slider-container">
                <Range
                  step={1}
                  min={0}
                  max={100}
                  values={hipsRange}
                  onChange={handleHipsChange}
                  renderTrack={({ props, children }) => (
                    <div {...props} className="slider-track">
                      {children}
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div {...props} className="slider-thumb" />
                  )}
                />
              </div>
              <div className="shoulder-filter">
                <div className="circle-input">
                  <span className="currency-symbol">"</span>
                  <input
                    type="number"
                    value={shoulderRange[0]}
                    onChange={(e) => handleShoulderChange([Number(e.target.value), shoulderRange[1]])}
                  />
                </div>
                <div className="filter-title-center">
                  Shoulders
                </div>
                <div className="circle-input">
                  <span className="currency-symbol">"</span>
                  <input
                    type="number"
                    value={shoulderRange[1]}
                    onChange={(e) => handleShoulderChange([shoulderRange[0], Number(e.target.value)])}
                  />
                </div>
              </div>
              <div className="slider-container">
                <Range
                  step={1}
                  min={0}
                  max={100}
                  values={shoulderRange}
                  onChange={handleShoulderChange}
                  renderTrack={({ props, children }) => (
                    <div {...props} className="slider-track">
                      {children}
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div {...props} className="slider-thumb" />
                  )}
                />
              </div>
              <div className="sleevelength-filter">
                <div className="circle-input">
                  <span className="currency-symbol">"</span>
                  <input
                    type="number"
                    value={sleeveLengthRange[0]}
                    onChange={(e) => handleSleeveLengthChange([Number(e.target.value), sleeveLengthRange[1]])}
                  />
                </div>
                <div className="filter-title-center">
                  Sleeve Length
                </div>
                <div className="circle-input">
                  <span className="currency-symbol">"</span>
                  <input
                    type="number"
                    value={sleeveLengthRange[1]}
                    onChange={(e) => handleSleeveLengthChange([sleeveLengthRange[0], Number(e.target.value)])}
                  />
                </div>
              </div>
              <div className="slider-container">
                <Range
                  step={1}
                  min={0}
                  max={100}
                  values={sleeveLengthRange}
                  onChange={handleSleeveLengthChange}
                  renderTrack={({ props, children }) => (
                    <div {...props} className="slider-track">
                      {children}
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div {...props} className="slider-thumb" />
                  )}
                />
              </div>
              <div className="waist-filter">
                <div className="circle-input">
                  <span className="currency-symbol">"</span>
                  <input
                    type="number"
                    value={waistRange[0]}
                    onChange={(e) => handleWaistChange([Number(e.target.value), waistRange[1]])}
                  />
                </div>
                <div className="filter-title-center">
                  Waist
                </div>
                <div className="circle-input">
                  <span className="currency-symbol">"</span>
                  <input
                    type="number"
                    value={waistRange[1]}
                    onChange={(e) => handleWaistChange([waistRange[0], Number(e.target.value)])}
                  />
                </div>
              </div>
              <div className="slider-container">
                <Range
                  step={1}
                  min={0}
                  max={100}
                  values={waistRange}
                  onChange={handleWaistChange}
                  renderTrack={({ props, children }) => (
                    <div {...props} className="slider-track">
                      {children}
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div {...props} className="slider-thumb" />
                  )}
                />
              </div>
              <div className="letout-filter">
                <div className="circle-input">
                  <span className="currency-symbol">"</span>
                  <input
                    type="number"
                    value={letoutRange[0]}
                    onChange={(e) => handleletoutChange([Number(e.target.value), letoutRange[1]])}
                  />
                </div>
                <div className="filter-title-center">
                  Letout
                </div>
                <div className="circle-input">
                  <span className="currency-symbol">"</span>
                  <input
                    type="number"
                    value={letoutRange[1]}
                    onChange={(e) => handleletoutChange([letoutRange[0], Number(e.target.value)])}
                  />
                </div>
              </div>
              <div className="slider-container">
                <Range
                  step={1}
                  min={0}
                  max={100}
                  values={letoutRange}
                  onChange={handleletoutChange}
                  renderTrack={({ props, children }) => (
                    <div {...props} className="slider-track">
                      {children}
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div {...props} className="slider-thumb" />
                  )}
                />
              </div>
              <div className="designer-filter">
                <select value={selectedDesigner} onChange={handleDesignerChange}>
                  <option value="">All Designers</option>
                  {designers.map(designer => (
                    <option key={designer} value={designer}>{designer}</option>
                  ))}
                </select>
              </div>
              <div className="year-filter">
                <select value={selectedYear} onChange={handleYearChange}>
                  <option value="">All Years</option>
                  {years.map(year => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
    </div>
  );
};

export default PriceFilter;






