import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import './UpdateBidModal.css';

const badWords = ["fuck", "bitch", "shit", "ass","hell","fag","faggot","nigga","nigger","dick","pussy","damn","fugly","poop","dike","arse","asshole",
"tity","slut","whore","titties","slutty","hooker","cunt","bullshit","crap","bastard","nigro","nigra","niggas","jesus christ","balls",
"cum","piss","niggaz","nig","jizz","skank","dildo","dildos","niglet","penis","vagina","chink","ching chong","ape","anus","rape",
"sex","sexual","sexy","fat","fetish","gay","lesbian","homo","homosexual","homosexuality","homosex","obey","sexism","sexist",
"obese","ugly","ugliest","uglyest","uglyass","uglyasses","uglyboi","uglyboy","uglyboys","uglygirl","uglygirls","uglygirly","blowjob",
"porn","semen","kill","murder","piss","sexualintercourse","bimbo","crack","cocaine","drugs","pornography","weed","drunk","shoot",
"marijuana","suck","nuts","nutts","sucknut"
]; 

function containsBadWord(input) {
  return badWords.some(badWord => input.toLowerCase().includes(badWord));
}

const UpdateBidModal = ({ bid, onClose, onUpdate }) => {
    const [amount, setAmount] = useState(bid.amount);
    const [description, setDescription] = useState(bid.description);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        // Trigger the modal to show with transition when the component mounts
        setShowModal(true);
    }, []);

    const handleClose = () => {
        setShowModal(false);
        setTimeout(onClose, 300); // Delay the onClose to allow transition
    };

    
    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        if (containsBadWord(value)) {
          Swal.fire({
            icon: 'error',
            title: 'Invalid Input',
            text: 'Your description contains inappropriate content. Please remove it.',
          });
          return;
        }
        setDescription(value);
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedData = {
            amount: amount,
            description: description,
            product_id: bid.product_id
        };
        onUpdate(bid.id, updatedData);
        handleClose();
    };

    return (
        <div className={showModal ? "modal30 show" : "modal30"}>
            <div className={showModal ? "modal-content30 show" : "modal-content30"}>
                <span className="close30" onClick={handleClose}>&times;</span>
                <form onSubmit={handleSubmit}>
                    <label>
                        Bid Amount:
                        <input type="number" value={amount} onChange={e => setAmount(e.target.value)} />
                    </label>
                    <label>
                        Description:
                        <textarea value={description} onChange={handleDescriptionChange} />
                    </label>
                    <button type="submit" className="Hello100">Update Bid</button>
                </form>
            </div>
        </div>
    );
};

export default UpdateBidModal;
