import React from 'react';
import './BidCard.css';

const BidCard = ({ bid, onAccept, onDecline, onOpenChat, onEndChat, onRemoveEndedChat, receiverUsername }) => {
    if (!bid) {
        return <p>Loading bid information...</p>;
    }

    // Hide the bid card if the chat was ended by the receiver
    if (bid.ended_by === receiverUsername) {
        return null;
    }

    const handleEndChat = () => {
        if (bid.chatRoomId) {
            onEndChat();
        } else {
            alert("Chat room not available.");
        }
    };

    const handleRemoveEndedChat = () => {
        onRemoveEndedChat(bid.id);
    };

    return (
        <div className="bid-card">
            <div className="bid-header">
                <h3>Received from: {bid.bidderName}</h3>
            </div>
            <div className="bid-info">
                <p><strong>Item:</strong> {bid.product.name}</p>
                <p><strong>Amount:</strong> ${bid.amount}</p>
                <p><strong>Description:</strong> {bid.description}</p>
            </div>
            <div className="bid-actions">
                {bid.status === 'inactive' && bid.ended_by !== receiverUsername ? (
                    <>
                        <p> {bid.bidderName} has ended the chat</p>
                        <button111 onClick={handleRemoveEndedChat} className="remove-chat-btn">Remove</button111>
                    </>
                ) : (
                    bid.status === 'accepted' ? (
                        <>
                            <button111 onClick={() => onOpenChat(bid.chatRoomId)} className="chat-btn">Chat</button111>
                            <button111 onClick={handleEndChat} className="end-chat-btn">End Chat</button111>
                        </>
                    ) : (
                        <>
                            <div className="action-buttons">
                                <button onClick={() => onAccept(bid.id)} className="accept-btn">✓</button>
                                <button onClick={() => onDecline(bid.id)} className="decline-btn">✗</button>
                            </div>
                        </>
                    )
                )}
            </div>
        </div>
    );
};

export default BidCard;
