import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './ChatPage.css';
import axios from 'axios';
import ChatList from './ChatList'; 

const client = axios.create({
    baseURL: "https://www.refeiscollective.com",
    withCredentials: true,
});

const badWords = ["fuck", "bitch", "shit", "ass","hell","fag","faggot","nigga","nigger","dick","pussy","damn","fugly","poop","dike","arse","asshole",
"tity","slut","whore","titties","slutty","hooker","cunt","bullshit","crap","bastard","nigro","nigra","niggas","jesus christ","balls",
"cum","piss","niggaz","nig","jizz","skank","dildo","dildos","niglet","penis","vagina","chink","ching chong","ape","anus","rape",
"sex","sexual","sexy","fat","fetish","gay","lesbian","homo","homosexual","homosexuality","homosex","obey","sexism","sexist",
"obese","ugly","ugliest","uglyest","uglyass","uglyasses","uglyboi","uglyboy","uglyboys","uglygirl","uglygirls","uglygirly","blowjob",
"porn","semen","kill","murder","piss","sexualintercourse","bimbo","crack","cocaine","drugs","pornography","weed","drunk","shoot",
"marijuana","suck","nuts","nutts","sucknut"
]; 

function containsBadWord(input) {
    return badWords.some(badWord => input.toLowerCase().includes(badWord));
}

const ChatPage = ({ isAuthenticated }) => {
    const { chatRoomId } = useParams();
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [ws, setWs] = useState(null);
    const [inputMessage, setInputMessage] = useState('');
    const [currentUser, setCurrentUser] = useState('');
    const currentUserRef = useRef(currentUser); 
    currentUserRef.current = currentUser;
    const [otherParticipant, setOtherParticipant] = useState('Loading...');
    const [chatRooms, setChatRooms] = useState([]);
    const [error, setError] = useState('');




    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }

    useEffect(() => {
        if (isAuthenticated) {
            client.get('/api/chatrooms/')
            .then(response => {
                setChatRooms(response.data);
            })
            .catch(error => console.error('Error fetching chat rooms:', error));
        }
    }, [isAuthenticated]); // Only re-fetch chat rooms when authentication status changes
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const userResponse = await client.get("/api/user");
                setCurrentUser(userResponse.data.user.username);
    
                const chatRoomResponse = await client.get(`/api/chatrooms/${chatRoomId}`);
                const otherParticipantName = chatRoomResponse.data.other_participant_names.length > 0 ? 
                    chatRoomResponse.data.other_participant_names.join(', ') : "No other participants";
                setOtherParticipant(otherParticipantName);
        
                const messagesResponse = await client.get(`/api/chat/${chatRoomId}/messages`);
                const formattedData = messagesResponse.data.map(item => ({
                    ...item,
                    timestamp: formatTimestamp(item.timestamp),
                    sentByMe: item.username === userResponse.data.user.username
                }));
                setMessages(formattedData);
            } catch (error) {
                console.error('Error fetching data:', error);
                setCurrentUser(null);
                setMessages([]);
                setOtherParticipant('Failed to load');
            }
        };
    
        if (isAuthenticated && chatRoomId) {
            fetchData();
        }
    }, [chatRoomId, isAuthenticated]); // Fetch data when isAuthenticated or chatRoomId changes
    

    useEffect(() => {
        const websocket = new WebSocket(`wss://www.refeiscollective.com/ws/chat/${chatRoomId}/`);
        websocket.onopen = () => console.log("WebSocket Connected");
        websocket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            const formattedMessage = {
                ...data,
                timestamp: formatTimestamp(data.timestamp),
                sentByMe: data.username === currentUserRef.current,
            };
            setMessages(prevMessages => [...prevMessages, formattedMessage]);
        };
        setWs(websocket);

        return () => {
            websocket.close();
        };
    }, [chatRoomId]); 

    const sendMessage = () => {
        if (ws && inputMessage) {
            if (containsBadWord(inputMessage)) {
                setError('Your message contains inappropriate content.');
                return;
            }
            ws.send(JSON.stringify({ message: inputMessage }));
            setInputMessage('');
            setError('');
        }
    };

    const handleRoomSelect = roomId => {
        navigate(`/chat/${roomId}`);
    };

    return (
        <div className="chat-container">
            <ChatList
                rooms={chatRooms}
                onRoomSelect={handleRoomSelect}
                selectedRoom={chatRoomId}
            />
            <div className="chat-area">
                <div className="chat-header">{otherParticipant}</div>
                <div className="chat-messages">
                    {messages.map((msg, index) => (
                        <div key={index} className={`chat-message ${msg.sentByMe ? 'sent-by-me' : 'sent-by-other'}`}>
                            <div className="message-bubble">
                                <span className="message-text">{msg.message}</span>
                                <span className="message-time">{msg.timestamp}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="chat-input-container">
                    <input
                        type="text"
                        className="chat-input"
                        placeholder="Type a message..."
                        value={inputMessage}
                        onChange={e => setInputMessage(e.target.value)}
                        onKeyPress={e => e.key === 'Enter' && inputMessage.trim() && sendMessage()}
                    />
                    <button className="send-button" onClick={sendMessage}>Send</button>
                </div>
                {error && <p className="error-message">{error}</p>}
            </div>
        </div>
    );
};

export default ChatPage;
