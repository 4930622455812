import React, { useState , useEffect} from 'react';
import ActiveBids from './ActiveBids';
import ReceivedBids from './ReceivedBids';
import './BidDashBoard.css';

const BidsDashboard = () => {
  const [activeTab, setActiveTab] = useState(''); 
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Smooth scroll to top when the page loads
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsVisible  (true); 
  }, []);
  

  const handleTabClick = (tab) => {
    if (activeTab === tab) {
      setActiveTab(''); // If the same tab is clicked, toggle it off
    } else {
      setActiveTab(tab); // Otherwise, switch to the new tab
    }
  };

  return (
    <div className={`dashboard11 ${isVisible ? 'fadeIn' : ''}`}>
      <div className="tabs11">
        <div 
          className={`tab-container ${activeTab === 'active' ? 'active' : ''}`} 
          onClick={() => handleTabClick('active')}
        >
          <span>Active Bids</span>
        </div>
        <div 
          className={`tab-container ${activeTab === 'received' ? 'active' : ''}`} 
          onClick={() => handleTabClick('received')}
        >
          <span>Received Bids</span>
        </div>
      </div>
      <div className="content11">
        {activeTab === 'active' ? <ActiveBids /> :
         activeTab === 'received' ? <ReceivedBids /> : 
         <p className="centered-text">Select a tab to view bids or click again to close</p>}
      </div>
    </div>
  );
};

export default BidsDashboard;
